<div class="wrapper vh-100" [ngStyle]="{ 'background-color': bg }">
  <app-slider
    [loginState]="loginState"
    (changeBg)="changeBg($event)"
  ></app-slider>
  <div class="form-wrapper">
    <app-form
      [resetPassword]="resetPassword"
      (loginState)="toggleSlider($event)"
    ></app-form>
  </div>
</div>
