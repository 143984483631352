<div>
  <form #resetForm (ngSubmit)="submitResetPassword()">
    <div class="form-group has-error mb-lg-4">
      <label for="password" class="font-20 font-500">Nytt lösenord*</label>
      <input
        required
        type="password"
        class="form-control input-line"
        id="password"
        placeholder="Ange dit lösenord"
        [(ngModel)]="data.password"
        name="password"
        #password="ngModel"
      />
      <span
        class="text-danger help-block"
        *ngIf="password.errors?.required && password.touched"
      >
        Lösenord fältet får inte vara tom
      </span>
    </div>
    <div class="form-group has-error">
      <label for="confirmPassword" class="font-20 font-500"
        >Repetera lösenord*</label
      >
      <input
        required
        type="password"
        class="form-control input-line"
        id="confirmPassword"
        placeholder="Repetera lösenord"
        [(ngModel)]="data.confirmPassword"
        name="confirmPassword"
        #confirmPassword="ngModel"
        (ngModelChange)="checkSame($event)"
      />

      <span
        class="text-danger help-block"
        *ngIf="confirmPassword.errors?.required && confirmPassword.touched"
      >
        Lösenord fältet får inte vara tom
      </span>

      <span class="text-danger help-block" *ngIf="noMatchPasswords === true">
        Lösenorden är inte lika
      </span>
    </div>
    <p class="text-info" *ngIf="info">{{ info }}</p>
    <p class="text-info" *ngIf="error">{{ error }}</p>
    <button
      type="submit"
      class="mt-4"
      [disabled]="
        confirmPassword.errors?.required ||
        !confirmPassword.dirty ||
        noMatchPasswords
      "
    >
      <p class="fw-bold">Reset Password</p>
    </button>
  </form>
  <p class="btn fw-bold m-3" (click)="toLogin()">Login</p>
</div>
