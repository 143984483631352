import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  HostListener,
  ViewChild,
  Renderer2,
} from "@angular/core";
import { FormsModule, NgForm } from "@angular/forms";

// import { TestServiceService } from "../../service/test-service.service";
import { Router } from "@angular/router";
import { PATHES } from "src/app/shared/paths";
import { AuthenticationService } from "../authentication.service";
import { CommonModule } from "@angular/common";
import { RegisterComponent } from "../register/register.component";
import { ModalComponent } from "src/app/shared/components/modal/modal.component";
import { ResetPasswordComponent } from "../reset-password/reset-password.component";
import { ResetPasswordRequestComponent } from "../reset-password-request/reset-password-request.component";
import { PostsService } from "src/app/features/posts/posts.service";
import { UserService } from "src/app/features/user/user.service";
import { AuthToken } from "src/app/shared/model/auth-token";
import { Login } from "src/app/shared/model/login";
import { AnimalProfileService } from "src/app/features/animal/animal-profile.service";
import { MessageService } from "src/app/shared/service/message.service";
import { SliderService } from "src/app/shared/service/slider-service.service";

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, RegisterComponent, ResetPasswordComponent, ResetPasswordRequestComponent, ModalComponent],
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.css"],
})
export class FormComponent implements OnInit {
  @ViewChild("resetForm", { static: false }) resetForm: NgForm;

  fadeInElement: HTMLElement;
  public loading: boolean = false;

  // Handle the loader state emitted by RegisterComponent
  handleLoadingState(isLoading: boolean) {
    this.loading = isLoading;
  }

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private animalService: AnimalProfileService,
    private MessageService: MessageService,
    private sliderService: SliderService,
    private postsService: PostsService,
    private renderer: Renderer2
  ) { }
  @Input() resetPassword: boolean;
  resetPasswordData: { password: string; confirmPassword: string } = {
    password: "",
    confirmPassword: "",
  };
  loginError: string = "";
  sendResetPasswordRequest: boolean = false;
  sendResetPasswordRequestData: { email: string } = { email: "" };
  noMatchPasswords: boolean = false;
  @Output() loginState: EventEmitter<boolean> = new EventEmitter();
  public showMessage: boolean;
  public loginData: Login;
  public inloggedUser: AuthToken;
  public showLogin: boolean = false;
  public showRegister: boolean = false;
  public screenWidth: number = 500;
  public tabletWidth: number = 744;
  

  ngOnInit(): void {
    
    this.loginData = new Login();
    // this.loginData.socialSecurityNumber = "";
    this.loginData.email = "";
    this.loginData.password = "";
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > this.tabletWidth) {
      this.showLogin = true;
    }
    if (this.screenWidth < this.tabletWidth) {
      this.isSmallScreen = true;
    }
    localStorage.removeItem("messageHubId");
    localStorage.removeItem("notificationHubId");

    let token = localStorage.getItem('jwt_token');
    //if (token) {
     // this.postsService.fetchAllPosts();
    //}
    

  }


  @HostListener("window:resize", ["$event"])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
    this.checkScreenSize();
  }

  public login(): void {
    this.authenticationService
      .getAuthentication(this.loginData)
      .subscribe((res) => {
        if (!res.user) {
          this.loginError = "Felaktiga inloggningsuppgifter!";
        }
        //if error
        if (res.token === 'error') { 
          this.loginError = "Ett fel inträffade. Försök igen senare."; 
          
          return; 
        }

        //if error end
        this.inloggedUser = res;
        if (!this.checkIfTokenEmpty()) {
          this.authenticationService.setToken(this.inloggedUser.token);

          console.info("-----login-----");
          console.info(res);
          console.info(this.inloggedUser);
          // this.testService.toggleAuthentication(true);
          this.authenticationService.startCountingDown();
          console.info("parse token");
          console.info(
            this.authenticationService.parseJwt(this.inloggedUser.token)
          );
          this.userService.setUserAsyncSubject(this.inloggedUser.user);
          this.userService.setUser(this.inloggedUser.user);
          // After successful log in
          // Getting all users animals
          this.animalService.getUserAnimals(this.inloggedUser.user.id);
          // Getting all users rooms
          this.MessageService.getUserPrivateRooms();
          this.router.navigate([PATHES.start]);
        }
      });
  }

  public checkIfTokenEmpty(): boolean {
    if (this.inloggedUser.token === "") {
      this.message(true);
      return true;
    } else {
      this.message(false);
      return false;
    }
  }

  public message(show: boolean) {
    if (show) {
      this.showMessage = true;
    } else {
      this.showMessage = false;
    }
  }

  public showLoginForm() {
    //console.log("to login");
    if (this.showLogin || this.screenWidth > this.tabletWidth) {
      this.login();
    } else {
      this.showLogin = true;
      this.showRegister = false;
      this.loginState.emit(this.showLogin);
      this.sliderService.toggleSlider(this.showLogin);
    }
  }

  public showRegisterForm() {
    // this.showLogin = false;
    this.showRegister = true;
    if (this.screenWidth < this.tabletWidth) {
      this.sliderService.toggleSlider(this.showRegister);
    }
  }
  public hideLog() {
    if (this.screenWidth < this.tabletWidth) {
      this.showLogin = false;
      this.loginState.emit(this.showLogin);
      this.sliderService.toggleSlider(this.showLogin);
    }
    this.showRegister = false;
  }

  UserRegistered(obj: any) {
    // console.log('User registered and it"s time to show login');
    this.loginData.email = obj.email;
    this.loginData.password = obj.password;

    this.hideLog();
    this.showLoginForm();
    
  }

  checkSame(input: string) {
    const secondPassword = input;
    const firstPassword = this.resetPasswordData.password;
    if (secondPassword !== firstPassword) {
      // stuff for form control
      this.noMatchPasswords = true;
      this.resetForm.controls.confirmPassword.markAsDirty();
      this.resetForm.form.controls.confirmPassword.setErrors(null);
    } else {
      // form control with errors
      this.noMatchPasswords = false;
      this.resetForm.form.controls.confirmPassword.setErrors({
        incorrect: true,
      });
      this.resetForm.controls.confirmPassword.markAsPristine();
    }
  }

  openModal() {
    console.log(
      "open send reset password request modal: ",
      this.sendResetPasswordRequest
    );

    this.sendResetPasswordRequest = true;
  }

  closeModal() {
    this.sendResetPasswordRequest = false;
  }

  // Send request to reset password
  submitResetPasswordRequest() {
    console.log(
      "send request to reset password: ",
      this.sendResetPasswordRequestData
    );
  }

  // Send the new password
  submitResetPassword() {
    if (
      this.noMatchPasswords ||
      !this.resetPasswordData.confirmPassword ||
      !this.resetPasswordData.password
    ) {
      return;
    }
    console.log("resetting password: ", this.resetPasswordData);

    // Send reset password to server
    // this.router.navigate([PATHES.login]);
  }

  
  isSmallScreen: boolean;

  private checkScreenSize() {
    this.isSmallScreen = window.innerWidth <= 744; 
  }

  isEmailValid(email: string): boolean {

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    return emailPattern.test(email);
  }

  get isButtonDisabled(): boolean {
    //button needs to be enabled on small screens to allow login
    if (this.isSmallScreen) { return false; }
    return !this.isEmailValid(this.loginData.email) || !this.loginData.password;
  }

}
