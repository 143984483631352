import { Component, inject, OnInit } from "@angular/core";
import { AuthenticationService } from "../authentication.service";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { Unsub } from "src/app/shared/unsub.class";
@Component({
  standalone: true,
  imports: [CommonModule, FormsModule],
  selector: "app-reset-password-request",
  templateUrl: "./reset-password-request.component.html",
  styleUrls: ["./reset-password-request.component.css"],
})
export class ResetPasswordRequestComponent extends Unsub {
  email: string = "";
  error: string = "";
  info: string = "";
  
  auth = inject(AuthenticationService) 

  resetError() {
    this.info = "";
    this.error = "";
  }
  // Send request to reset password
  submitResetPasswordRequest() {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$/;
    const match = regex.test(this.email);
    if (!match) {
      this.error = "Email is not valid";
      return;
    }

    // this.authenticationService
    //   .sendResetPasswordRequest(this.email)
    //   .pipe(takeUntil(this.unsubscribe$))
    //   .subscribe(
    //     (res) => {
    //       console.log("response from server: ", res);
    //       this.info = "Check your email!";
    //     },
    //     (err) => {
    //       this.error = "Sorry, Something went wrong!";
    //     }
    //   );
  }
}
