<div
  class="background position-relative p-3 text-center"
  [ngClass]="currentSlide.class"
>
  <div
    class="flex-md-column align-items-center"
    [ngClass]="currentSlide.order == 0 ? '' : 'd-flex'"
  >
    <img
      class="imgIcon"
      *ngIf="currentSlide.icon"
      [src]="'../../assets/icons/' + currentSlide.icon + '.svg'"
      alt=""
    />

    <p
      class="ps-3 mt-md-3 text-start"
      [ngClass]="currentSlide.order == 0 ? 'text-center mt-3' : ''"
    >
      {{ currentSlide.mainText }}
    </p>
  </div>
  <p class="mt-md-0" [ngClass]="currentSlide.order == 0 ? 'mt-0' : 'mt-3'">
    {{ currentSlide.subText }}
  </p>
  <div
    *ngIf="!loginStateService"
    class="slider-controller d-flex justify-content-center position-absolute start-50 translate-middle-x"
  >
    <div
      *ngFor="let slide of slides; let index = index"
      class="slider-controller-div rounded-circle m-2"
      [ngClass]="{ 'active-div': index === currentSlide.order }"
      (click)="changeSlide(index)"
    ></div>
  </div>
</div>
