import { Component, OnInit } from "@angular/core";
import { SliderComponent } from "./slider/slider.component";
import { FormComponent } from "./form/form.component";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  imports: [
    CommonModule, 
    SliderComponent, 
    FormComponent
  ],
  selector: "app-authentication",
  templateUrl: "./authentication.component.html",
  styleUrls: ["./authentication.component.css"],
})
export class AuthenticationComponent implements OnInit {
  constructor() {}

  resetPassword: boolean = false;
  public path = "";
  public loginState: boolean;
  public dialogueResponse: boolean;

  public bg = "#785f66";
  ngOnInit() {
    this.resetPassword = window.location.pathname.includes("resetPassword");
  }

  changeBg(color: string): void {
    this.bg = color;
  }
  toggleSlider(event: boolean) {
    this.loginState = event;
  }
}
