import { CommonModule } from "@angular/common";
import { Component, OnInit,Input, Output, EventEmitter, SimpleChanges } from "@angular/core";
import{Subscription} from 'rxjs'
import { SliderService } from "src/app/shared/service/slider-service.service";

interface Slide {
  class: string;
  icon: string | null;
  mainText: string | null;
  subText: string| null;
  order: number;
  bgColor: string;
  wrapper: string;
}

@Component({
  standalone: true,
  imports:  [CommonModule],
  selector: "app-slider",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.css"],
})
export class SliderComponent implements OnInit {
  @Output() changeBg: EventEmitter<string> = new EventEmitter();
  @Input() loginState: boolean
  loginStateService: boolean
  // @Input() set loginState( loginState: string){
  //   console.log(typeof loginState)
  //   if(loginState == "true"){
  //     console.log('stop slides')
  //     this.stopSlides()
  //   }
  //   if(loginState == "false"){
  //     console.log('roll slides')
  //     this.rollSlides()
  //   }
  // }
  constructor(private sliderService: SliderService) {
    this.sliderService.onToggleSlider().subscribe(loginState => {
      // console.log('loginState from slider service: ', loginState)
      // console.log('login state from props: ', this.loginState)
      this.loginStateService = loginState
      if(loginState){
        this.stopSlides()
      }else{
        this.rollSlides()
      }
    })
  }
  public slides: Slide[] = [
    {
      class: "slide1",
      icon: "mainG",
      mainText: "Välkommen till PetPals!",
      subText: "Sidan för djurägare ",
      order: 0,
      bgColor: "#785f66",
      wrapper: "wrapper1",
    },
    {
      class: "slide2",
      icon: "activityG",
      mainText: "Träning, aktiviteter  och evenemang!",
      subText:
        "Hitta träningstips, roliga aktiviteter och evenemang för dig och ditt djur. ",
      order: 1,
      bgColor: "#7c6c42",
      wrapper: "wrapper2",
    },
    {
      class: "slide3",
      icon: "placesG",
      mainText: "Djurvänliga platser!",
      subText:
        "Hitta till djurvänliga platser, cafén, resturanger, hotell och flyg. ",
      order: 2,
      bgColor: "#306b56",
      wrapper: "wrapper3",
    },
    {
      class: "slide4",
      icon: "friendsG",
      mainText: "Hitta vänner!",
      subText:
        "Dela bilder, erfarenheter och aktiviteter med dina vänner eller hitta nya vänner! ",
      order: 3,
      bgColor: "#343952",
      wrapper: "wrapper4",
    },
  ];
  stopSlide: Slide = {
    class: "slide1",
    icon: null,
    mainText: null,
    subText: null,
    order: 0,
    bgColor: "#785f66",
    wrapper: "wrapper1",
  };
  setIntervalId: any = null;
  public slideIndex: number = 0;
  public currentSlide: Slide = this.slides[this.slideIndex];
  public delayTime: number = 5000;
  ngOnInit(): void {
    this.rollSlides();
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   // changes.prop contains the old and the new value...
  //   if(changes.loginState.currentValue === "true"){
  //     console.log('take action to stop slider')
  //     this.stopSlides()
  //   } else if(changes.loginState.currentValue === "false"){
  //     console.log('take action to start sldier')
  //     this.rollSlides()
  //   }
  // }
  public rollSlides() {
    // console.log("start slider");
    this.currentSlide = this.slides[0]
    if(this.setIntervalId !== null){
      clearInterval(this.setIntervalId)
    }


    this.setIntervalId = setInterval(() => {
      if (this.slideIndex === this.slides.length) {
        this.slideIndex = 0;
      }
      this.currentSlide = this.slides[this.slideIndex];
      this.changeBg.emit(this.slides[this.slideIndex].bgColor);
      this.slideIndex++;
    }, this.delayTime);
  }
  public stopSlides() {
    // console.log('stop slider: ')
    clearInterval(this.setIntervalId);
    this.currentSlide = this.stopSlide
     this.changeBg.emit(this.stopSlide.bgColor);
  }

  public changeSlide(index: number) {
    this.slideIndex = index;
    this.currentSlide = this.slides[this.slideIndex];
    this.changeBg.emit(this.slides[this.slideIndex].bgColor);
  }



}
