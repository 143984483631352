import { Component, OnInit, ViewChild } from "@angular/core";
import { FormsModule, NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { CommonModule } from "@angular/common";
import { AuthenticationService } from "../authentication.service";
import { PATHES } from "src/app/shared/paths";
import { Unsub } from "src/app/shared/unsub.class";
@Component({
  standalone: true,
  imports: [CommonModule, FormsModule],
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"],
})
export class ResetPasswordComponent extends Unsub {
  @ViewChild("resetForm", { static: false }) resetForm: NgForm;

  data: { password: string; confirmPassword: string } = {
    password: "",
    confirmPassword: "",
  };
  noMatchPasswords: boolean = false;
  info: string = "";
  error: string = "";
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute
  ) {
    super();
  }

  toLogin() {
    this.router.navigate([PATHES.login]);
  }
  checkSame(input: string) {
    this.info = "";
    const secondPassword = input;
    const firstPassword = this.data.password;
    if (secondPassword !== firstPassword) {
      // stuff for form control
      this.noMatchPasswords = true;
      this.resetForm.controls.confirmPassword.markAsDirty();
      this.resetForm.form.controls.confirmPassword.setErrors(null);
    } else {
      // form control with errors
      this.noMatchPasswords = false;
      this.resetForm.form.controls.confirmPassword.setErrors({
        incorrect: true,
      });
      this.resetForm.form.controls.confirmPassword.markAsPristine();
    }
  }

  // Send the new password
  submitResetPassword() {
    if (
      this.noMatchPasswords ||
      !this.data.confirmPassword ||
      !this.data.password
    ) {
      return;
    }
    const token = this.route.snapshot.params["token"];
    console.log("resetting password: ", this);
    console.log("params: ", token);
    this.authenticationService
      .sendResetPassword(token, this.data.password)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          console.log("success resetting password:", res);
          this.info = "Success resettign password";
          this.router.navigate([PATHES.login]);
        },
        (err) => {
          console.log("error resetting password: ", err);
          this.error = "Sorry, Something went wrong!";
        }
      );
    // this.router.navigate([PATHES.login]);
  }
}
