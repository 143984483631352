import { Routes } from "@angular/router";
import { PageNotFoundComponent } from "./shared/components/page-not-found/page-not-found.component";
import ROUTES from "./shared/paths";
import { AuthenticationComponent } from "./core/auth/authentication.component";
import { AuthGuardService } from "./core/auth/auth-guard.service";

export const routes: Routes = [
  {
    path: ROUTES.AppRoutes.resetPassword,
    component: AuthenticationComponent
  },
  {
    path: ROUTES.AppRoutes.login,
    component: AuthenticationComponent
  },
  // tslint:disable-next-line: max-line-length
  {
    path: "",
    loadChildren: () => import("src/app/core/layout/main-view.routes").then(m => m.routes),
    canActivate: [AuthGuardService]
  },

  //Wild Card Route for 404 request
  { path: "**", pathMatch: "full", component: PageNotFoundComponent},
];
