import { Injectable } from "@angular/core";

import { AuthenticationService } from "./authentication.service";
import { Router } from "@angular/router";
import { PATHES } from "../../shared/paths";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate() {
    if (!this.authenticationService.isTokenExpired()) {
      return true;
    }
    this.authenticationService.cleanTokenData();
    this.router.navigate([PATHES.login]);

    return false;
  }
}
