<div class="form-container position-relative p-4 bg-white text-center">

  <div *ngIf="loading" style="position: absolute !important; top: 0 !important; left: 0 !important; right: 0 !important; bottom: 0 !important; background-color: rgba(0, 0, 0, 0.5) !important; color: white !important; display: flex !important; align-items: center !important; justify-content: center !important; z-index: 1500 !important; border-radius: 15px !important; mix-blend-mode: normal; ">
    <h1>Hämtar info&nbsp;&nbsp;</h1>
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="logo-div bg-white rounded-circle position-absolute start-50 translate-middle-x">
      <img src="../../assets/icons/mainG.svg" alt="" height="80" />

    </div>
    <span *ngIf="(showLogin && screenWidth < tabletWidth) || showRegister"
          role="button"
          class="fw-bold me-4 mt-2 position-absolute end-0 top-0"
          (click)="hideLog()">
      <img src="../../../assets/icons/close.svg" alt="close" />
    </span>
    <p class="font-500 p-m-2 pt-lg-4">
      {{ resetPassword ? "Reset password" : "Välkommen!" }}
    </p>

    <div class="login-section" *ngIf="!showRegister && !resetPassword">
      <div id="login"
           [ngClass]="{
           collapse: screenWidth < tabletWidth && showLogin == false,
        show: showLogin == true
      }"
    >
      <form (ngSubmit)="showLoginForm()">
        <span *ngIf="loginError" class="text-danger help-block">
          {{ loginError }}
        </span>
        <div class="mb-lg-4">
          <input
            pattern="^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            required
            type="email"
            [email]="loginData.email !== ''"
            class="form-control input-line"
            id="email1"
            aria-describedby="emailHelp"
            placeholder="E-postadress"
            [(ngModel)]="loginData.email"
            name="email"
            #emailControl="ngModel"
          />
          <span
            class="text-danger help-block"
            *ngIf="emailControl.errors?.pattern && emailControl.touched"
          >
            Ogiltig e-postadress
          </span>
          <span
            class="text-danger help-block"
            *ngIf="emailControl.errors?.required && emailControl.touched"
          >
            Email fältet får inte vara tom
          </span>
        </div>
        <input
          required
          type="password"
          class="form-control input-line mb-lg-4"
          id="exampleInputPassword"
          placeholder="Lösenord"
          [(ngModel)]="loginData.password"
          name="password"
          #passwordControl="ngModel"
        />
        <span
          class="text-danger help-block"
          *ngIf="passwordControl.errors?.required && passwordControl.touched"
        >
          Lösenord fältet får inte vara tom
        </span>

        <button type="submit" class="d-none"></button>
      </form>
      <div class="text-end">
        <p class="btn fw-bold mb-2 mb-lg-4" (click)="openModal()">
          Glömt lösen?
        </p>
      </div>
    </div>
      <button data-bs-target="#login"
              [attr.data-bs-toggle]="showLogin ? '' : 'collapse'"
              aria-controls="login"
              aria-expanded="false"
              aria-label="Toggle login"
              (click)="showLoginForm()"
              [disabled]="isButtonDisabled"
              [ngClass]="{
    'btn-disabled': isButtonDisabled
      }">
        <p class="fw-bold">Logga in</p>
      </button>
      <div class="create-account"
           [ngClass]="showLogin && screenWidth < tabletWidth ? 'hide-form' : ''">
        <p class="pt-lg-4 pb-lg-2">Inte medlem?</p>
        <button class="create-account-button" (click)="showRegisterForm()">
          <p class="fw-bold">Skapa konto</p>
        </button>
      </div>
    </div>
    <div class="register-section"
         [ngClass]="{ 'hide-form': !showRegister, 'show-form': showRegister }">
      <app-register (UserRegistered)="UserRegistered($event)"
                    (loadingStateChanged)="handleLoadingState($event)"></app-register>
    </div>

    <app-reset-password *ngIf="resetPassword"></app-reset-password>

    <app-modal *ngIf="sendResetPasswordRequest" (closeModal)="closeModal()">
      <app-reset-password-request></app-reset-password-request>
      <p class="btn fw-bold m-3" (click)="closeModal()">Avbryt</p>
    </app-modal>
  </div>
